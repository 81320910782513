import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../components/home.vue'),
    children: [
      {
        // 首页
        path: 'index',
        name: 'index',
        component: () => import('../components/children/index/index.vue')
      },
      {
        // 简介
        path: 'introduce',
        name: 'introduce',
        component: () => import('../components/children/introduce/index.vue')
      },
      {
        // 市级管理
        path: 'manage',
        name: 'manage',
        redirect: 'manage/exhibition',
        component: () => import('../components/children/manage/manageMenu.vue'),
        children: [
          {
            // 企业展示
            path: 'exhibition',
            name: 'manage-exhibition',
            component: () => import('../components/children/manage/exhibition.vue')
          }, {
            // 大数据
            path: 'bigData',
            name: 'manage-bigData',
            component: () => import('../components/children/manage/bigData.vue')
          }, 
          {
            // 政务要闻
            path: 'news',
            name: 'manage-news',
            component: () => import('../components/children/manage/news.vue')
          },
          
          {
            // 政务公开
            path: 'open',
            name: 'manage-open',
            component: () => import('../components/children/manage/open.vue')
          }, {
            // 政务服务
            path: 'service',
            name: 'manage-service',
            component: () => import('../components/children/manage/service.vue')
          }, {
            // 组织生活
            path: 'life',
            name: 'manage-life',
            component: () => import('../components/children/manage/life.vue')
          }, {
            // 文件下发
            path: 'file',
            name: 'manage-file',
            component: () => import('../components/children/manage/file.vue')
          }, {
            // 表格下发
            path: 'table',
            name: 'manage-table',
            component: () => import('../components/children/manage/table.vue')
          },
          {
            // 表格详情
            path: 'tableDetail',
            name: 'manage-tableDetail',
            component: () => import('../components/children/manage/tableDetail.vue')
          },
          {
            // 表格详情
            path: 'import',
            name: 'manage-import',
            component: () => import('../components/children/manage/import.vue')
          },
          
          {// 工信管理-点击下发文件跳转--上传文件页面
            path: 'uploadFile',
            name: 'manage-uploadFile',
            component: () => import('../components/common/manage/upload.vue'),
          },
          {// 工信管理-自定义-图文视频
            path: 'customizeInfoPages/:handleIdx/:displayType/:isMenuId',
            name: 'manageInfoPages',
            component: () => import('@/components/common/customize/subNewsCustomize.vue'),
          },
        ],
      },
      {
        // 区域平台
        path: 'area',
        name: 'area',
        redirect: 'area/areaMenu/exhibition',
        component: () => import('../components/children/area/index.vue'),
        children: [
          {
            path: 'areaMenu',
            name: 'areaMenu',
            component: () => import('../components/children/area/areaMenu.vue'),
            children: [
              {
                // 园区展示
                path: 'exhibition',
                name: 'exhibition',
                component: () => import('../components/children/area/exhibition.vue')
              }, {
                // 大数据
                path: 'bigData',
                name: 'bigData',
                component: () => import('../components/children/area/bigData.vue')
              }, {
                // 产业新闻
                path: 'news',
                name: 'news',
                component: () => import('../components/children/area/news.vue')
              }, {
                // 行业分析
                path: 'analysis',
                name: 'analysis',
                component: () => import('../components/children/area/analysis.vue')
              }, {
                // 通知通告
                path: 'notice',
                name: 'notice',
                component: () => import('../components/children/area/notice.vue')
              },
              // {
              //   // 车辆管理
              //   path: 'driving',
              //   name: 'driving',
              //   component: () => import('../components/children/area/driving.vue'),
              //   meta: {
              //     link: 'https://www.cxiov.com/#/monitor/realTimeMon',
              //   }
              // },
            ]
          },
          // 园区展示二级页面
          {
            path: 'areaSubMenu',
            name: 'areaSubMenu',
            redirect: 'area/areaSubMenu/areaSubExhibition',
            component: () => import('../components/children/area/subArea/areaSubMenu.vue'),
            children: [
              {
                path: 'areaSubExhibition',
                name: 'areaSubExhibition',
                component: () => import('../components/children/area/subArea/subExhibition.vue'),
              },
              {
                path: 'areaSubBigData',
                name: 'areaSubBigData',
                component: () => import('../components/children/area/subArea/areaSubBigData.vue'),
              },
              {
                path: 'areaSubFile',
                name: 'areaSubFile',
                component: () => import('../components/children/area/subArea/fileNew.vue'),
              }, {
                path: 'areaSubTable',
                name: 'areaSubTable',
                component: () => import('../components/children/area/subArea/areaSubTableNew'),
              },
              {
                path: 'areaSubNews',
                name: 'areaSubNews',
                component: () => import('../components/children/area/subArea/areaSubNews.vue'),
              },
              {
                path: 'areaSubAnalysis',
                name: 'areaSubAnalysis',
                component: () => import('../components/children/area/subArea/areaSubAnalysis.vue'),
              },
              {
                path: 'areaSubNotice',
                name: 'areaSubNotice',
                component: () => import('../components/children/area/subArea/areaSubNotice.vue'),
              },
              {
                path: 'areaSubImport',
                name: 'areaSubImport',
                component: () => import('../components/children/area/subArea/areaSubImport.vue'),
              },
              {// 区域平台展示-子页面-点击下发文件跳转--上传文件页面
                path: 'areaSubUploadFile',
                name: 'areaSubUploadFile',
                component: () => import('../components/common/manage/upload.vue'),
              },
              {// 区域平台-子页面-图文视频
                path: 'customizeInfoPages/:handleIdx/:displayType/:isMenuId',
                name: 'areaInfoPages',
                component: () => import('@/components/common/customize/subNewsCustomize.vue'),
              },
            ],
          }
        ],
      },
      {
        // 产业链
        path: 'chain',
        name: 'chain',
        redirect: 'chain/chainMenu/exhibition',
        component: () => import('../components/children/chain/index.vue'),
        children: [
          {
            //二级菜单的页面
            path: 'chainMenu',
            name: 'chainMenu',
            component: () => import('../components/children/chain/chainMenu.vue'),
            children: [
              {
                // 产业链展示
                path: 'exhibition',
                name: 'chain-exhibition',
                component: () => import('../components/children/chain/exhibitionNew.vue')
              },
              {
                // 大数据
                path: 'bigData',
                name: 'chain-bigData',
                component: () => import('../components/children/chain/bigData.vue')
              },
              {
                // 产业新闻
                path: 'news',
                name: 'chain-news',
                component: () => import('../components/children/chain/news.vue')
              },
              {
                // 行业分析
                path: 'analysis',
                name: 'chain-analysis',
                component: () => import('../components/children/chain/analysis.vue')
              },
              {
                // 通知公告
                path: 'notice',
                name: 'chain-notice',
                component: () => import('../components/children/chain/notice.vue')
              },
            ]
          },
          {
            // 产业链展示-子页面
            path: 'chainSubMenu',
            name: 'chainSubMenu',
            redirect: 'chain/chainSubMenu/chainSubExhibition',
            component: () => import('../components/children/chain/subChain/chainSubMenu.vue'),
            children: [
              {
                // 产业链展示-子页面
                path: 'chainSubExhibition',
                name: 'chainSubExhibition',
                component: () => import('../components/children/chain/subChain/subExhibition.vue'),
              },
              {
                // 产业链展示-子页面
                path: 'chainSubBigData',
                name: 'chainSubBigData',
                component: () => import('../components/children/chain/subChain/chainSubBigData.vue'),
              },
              {// 产业链展示-子页面
                path: 'chainSubTable',
                name: 'chainSubTable',
                component: () => import('../components/children/chain/subChain/chainSubTableNew.vue'),
              },
              {
                // 产业链展示-子页面
                path: 'chainSubFile',
                name: 'chainSubFile',
                component: () => import('../components/children/chain/subChain/fileNew.vue'),
              },
              {
                // 产业链展示-子页面
                path: 'chainSubNews',
                name: 'chainSubNews',
                component: () => import('../components/children/chain/subChain/chainSubNews.vue'),
              },
              {
                // 产业链展示-子页面
                path: 'chainSubNews',
                name: 'chainSubNews',
                component: () => import('../components/children/chain/subChain/chainSubNews.vue'),
              },
              {
                // 产业链展示-子页面
                path: 'chainSubAnalysis',
                name: 'chainSubAnalysis',
                component: () => import('../components/children/chain/subChain/chainSubAnalysis.vue'),
              },
              {
                // 产业链展示-子页面
                path: 'chainSubNotice',
                name: 'chainSubNotice',
                component: () => import('../components/children/chain/subChain/chainSubNotice.vue'),
              },
              {
                // 产业链展示-子页面-数据导入
                path: 'chainSubImport',
                name: 'chainSubImport',
                component: () => import('../components/children/chain/subChain/chainSubImport.vue'),
              },
              {// 产业链-子页面-点击下发文件跳转--上传文件页面
                path: 'chainSubUploadFile',
                name: 'chainSubUploadFile',
                component: () => import('../components/common/manage/upload.vue'),
              },
              {// 产业集群-子页面-图文视频
                path: 'customizeInfoPages/:handleIdx/:displayType/:isMenuId',
                name: 'chainInfoPages',
                component: () => import('@/components/common/customize/subNewsCustomize.vue'),
              },
            ]
          }
        ],
      },
      {
        // 产业集群
        path: 'colony',
        name: 'colony',
        redirect: 'colony/colonyMenu/exhibition',
        component: () => import('../components/children/colony/index.vue'),
        children: [
          {
            //二级菜单的页面
            path: 'colonyMenu',
            name: 'colonyMenu',
            component: () => import('../components/children/colony/colonyMenu.vue'),
            children: [
              {
                // 产业链展示
                path: 'exhibition',
                name: 'colony-exhibition',
                component: () => import('../components/children/colony/exhibitionNew.vue')
              },
              {
                // 大数据
                path: 'bigData',
                name: 'colony-bigData',
                component: () => import('../components/children/colony/bigData.vue')
              },
              {
                // 产业新闻
                path: 'news',
                name: 'colony-news',
                component: () => import('../components/children/colony/news.vue')
              },
              {
                // 行业分析
                path: 'analysis',
                name: 'colony-analysis',
                component: () => import('../components/children/colony/analysis.vue')
              },
              {
                // 通知公告
                path: 'notice',
                name: 'colony-notice',
                component: () => import('../components/children/colony/notice.vue')
              },
            ]
          },
          {
            // 产业链展示-子导航
            path: 'colonySubMenu',
            name: 'colonySubMenu',
            redirect: 'colony/colonySubMenu/colonySubExhibition',
            component: () => import('../components/children/colony/subColony/colonySubMenu.vue'),
            children: [
              {// 产业链展示-子页面
                path: 'colonySubExhibition',
                name: 'colonySubExhibition',
                component: () => import('../components/children/colony/subColony/subExhibition.vue'),
              },
              {// 产业链展示-子页面
                path: 'colonySubBigData',
                name: 'colonySubBigData',
                component: () => import('../components/children/colony/subColony/colonySubBigData.vue'),
              },
              {// 产业链展示-子页面
                path: 'colonySubFile',
                name: 'colonySubFile',
                component: () => import('../components/children/colony/subColony/fileNew.vue'),
              },
              {// 产业链展示-子页面
                path: 'colonySubTable',
                name: 'colonySubTable',
                component: () => import('../components/children/colony/subColony/colonySubTableNew.vue'),
              },
              {// 产业链展示-子页面
                path: 'colonySubNews',
                name: 'colonySubNews',
                component: () => import('../components/children/colony/subColony/colonySubNews.vue'),
              },
              {// 产业链展示-子页面
                path: 'colonySubAnalysis',
                name: 'colonySubAnalysis',
                component: () => import('../components/children/colony/subColony/colonySubAnalysis.vue'),
              },
              {// 产业链展示-子页面
                path: 'colonySubNotice',
                name: 'colonySubNotice',
                component: () => import('../components/children/colony/subColony/colonySubNotice.vue'),
              },
              {// 产业链展示-子页面-数据导入
                path: 'colonySubImport',
                name: 'colonySubImport',
                component: () => import('../components/children/colony/subColony/colonySubImport.vue'),
              },
              {// 产业链展示-子页面-点击下发文件跳转--上传文件页面
                path: 'colonySubUploadFile',
                name: 'colonySubUploadFile',
                component: () => import('../components/common/manage/upload.vue'),
              },
              {// 产业集群-子页面-图文视频
                path: 'customizeInfoPages/:handleIdx/:displayType/:isMenuId',
                name: 'colonyInfoPages',
                component: () => import('@/components/common/customize/subNewsCustomize.vue'),
              },
            ]
          },
          {// 产业链展示-子页面-更多集群
            path: 'colonyMore',
            name: 'colonyMore',
            component: () => import('../components/children/colony/colonyMore.vue'),
          },
        ],
      },
      {
        //公司详情
        path: 'company',
        name: 'company',
        redirect: '/home/company/companyInfoPages/0/baseInfo/baseInfo',
        component: () => import('../components/children/company/index.vue'),
        children: [
          {
            path: 'companyInfoPages/:handleIdx/:menuMold/:isMenuId',//客户管理-企业信息等
            name: 'companyInfoPages',
            component: () => import('@/components/children/company/exhibition/infoPages.vue')
          },
          // {
          //   path: 'corporateNews',//客户管理-企业信息等
          //   name: 'corporateNews',
          //   component: () => import('@/components/children/company/cpns.vue')
          // },
          {
            path: 'productShow',//客户管理-企业信息等
            name: 'productShow',
            component: () => import('@/components/children/company/productShow.vue')
          },
          // {
          //   //二级菜单的页面
          //   path: 'exhibition',
          //   name: 'company-exhibition',
          //   component: () => import('../components/children/company/exhibition.vue'),
          // },
          {
            //二级菜单的页面
            path: 'videoDetail',
            name: 'company-videoDetail',
            component: () => import('../components/children/company/videoDetail.vue'),
          }
        ]
      },
      {
        // 伙伴新闻详情
        path: 'cpns',
        name: 'cpns',
        component: () => import('../components/children/cpns/index.vue')
      },
      {
        // 投诉预览
        path: 'complaint',
        name: 'complaint',
        component: () => import('../components/children/complaint/index.vue')
      },
      {
        // 投诉预览图文
        path: 'textReview',
        name: 'textReview',
        component: () => import('../components/children/complaint/imgTextReview.vue')
      },
   
      {
        // 投诉预览产品详情
        path: 'cpDtailTs',
        name: 'cpDtailTs',
        component: () => import('../components/children/complaint/cpDtail.vue')
      },

      {
        // 审核预览
        path: 'auditDetail',
        name: 'auditDetail',
        component: () => import('../components/children/auditDetail/index.vue')
      },
      {
        // 审核预览产品详情
        path: 'cpDtail',
        name: 'cpDtail',
        component: () => import('../components/children/auditDetail/cpDtail.vue')
      },
      {
        // 审核预览图文
        path: 'imgTextReview',
        name: 'imgTextReview',
        component: () => import('../components/children/auditDetail/imgTextReview.vue')
      },

     
      {
        // 审核
        path: 'examine',
        name: 'examine',
        component: () => import('../components/children/examine/index.vue')
      },
      {
        // 伙伴产品详情
        path: 'prodt',
        name: 'prodt',
        component: () => import('../components/children/prodt/index.vue')
      },

      {
        // 小企业赋能
        path: 'enabling',
        name: 'enabling',
        component: () => import('../components/children/enabling/index.vue')
      },

      {
        // 小企业赋能详情
        path: 'enablingDetail',
        name: 'enabling-Detail',
        component: () => import('../components/children/enabling/videoDetail.vue')
      },
      {
        // 工联伙伴
        path: 'interconnection',
        name: 'interconnection',
        redirect: 'interconnection/info/0/interconnectionInfo',
        component: () => import('../components/children/interconnection/interconnectionMenu.vue'),
        children: [
          {
            // 视频
            path: 'info/:handleIdx/:isMenuId',
            name: 'interconnectionInfo',
            component: () => import('../components/children/interconnection/index.vue')
          },
        ]
      },
   
      {
        // 人员管理
        path: 'userList',
        name: 'userList',
        component: () => import('../components/children/setting/userList.vue')
      },
      {
        // 人员管理-新增、编辑人员
        path: 'userEdit',
        name: 'userEdit',
        component: () => import('../components/children/setting/userEdit.vue')
      },
      {
        // 人员管理-综合管理
        path: 'multipleManage',
        name: 'multipleManage',
        component: () => import('../components/children/setting/multipleManage.vue')
      },
      {
        // 作品审核-列表
        path: 'reviewList',
        name: 'reviewList',
        component: () => import('../components/children/setting/reviewList.vue')
      },
      {
        // 作品审核-视频审核
        path: 'reviewVideo',
        name: 'reviewVideo',
        component: () => import('../components/children/setting/reviewVideo.vue')
      },
      {
        // 作品审核-图文审核
        path: 'reviewNews',
        name: 'reviewNews',
        component: () => import('../components/children/setting/reviewNews.vue')
      },
      {
        // 作品审核-审核记录
        path: 'reviewLog',
        name: 'reviewLog',
        component: () => import('../components/children/setting/reviewLog.vue')
      },
      {
        // 数据配置
        path: 'dataConfig',
        name: 'dataConfig',
        component: () => import('../components/children/setting/dataConfig.vue')
      },
      {
        // 人员管理-标签管理
        path: 'labelManage',
        name: 'labelManage',
        component: () => import('../components/children/setting/labelManage.vue')
      },
      {
        // 下载APP
        path: 'download',
        name: 'download',
        component: () => import('@/components/children/download/downloadApp.vue')
      },
      // 强制刷新当前页所用的中间跳转页
      {
        path: '/redirect/:path*',
        name:'redirectPage',
        component: () => import('@/components/common/tools/redirect.vue')
      }
    ]
  },
  
];

const router = new VueRouter({
  routes
});
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    //let token = sessionStorage.getItem('userInfo');
    let token = localStorage.getItem('token');
    let userInfo = sessionStorage.getItem('userInfo');
    if (token === null || token === '' || userInfo === null || userInfo === '') {
      next({ path: '/login' });
    } else {
      next();
    }
  }
});
export default router
