//在request.js

import axios from 'axios'
import store from '../store'
import router from '../router'
import qs from 'qs'

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let requests = [];
// 创建一个实例
const service = axios.create({
    headers: {
        "Authorization": 'Bearer ' + store.getters.token
    },
    baseURL: store.state.baseUrl,
    //timeout: 20000 // request timeout
});

// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
service.setToken = (token) => {
    service.defaults.headers['Authorization'] = 'Bearer ' + token;
    store.commit('setToken', token)
};

//获取新的token请求
function refreshToken() {
    return axios({
        method: 'get',
        headers: {
            "Authorization": 'Bearer ' + store.getters.token
        },
        url: store.state.baseUrl + 'admin/refreshToken'
    }).then(res => res.data).catch(res => {
        //重新请求token失败，跳转到登录页
        /*router.push({
            path: '/login'
        });*/
        console.log(res)
    })
}

// 拦截返回的数据
service.interceptors.response.use(response => {
    // 接下来会在这里进行token过期的逻辑处理
    const { code } = response.data;
    // 说明token过期了,获取新的token
    if (code === 401) {
        const config = response.config;
        //判断一下状态
        if (!isRefreshing) {
            //修改状态，进入更新token阶段
            isRefreshing = true;
            // 获取当前的请求
            return refreshToken().then(res => {
                // 刷新token成功，将最新的token更新到header中，同时保存在localStorage中
                console.log(res);
                if (res.data.code === 200) {
                    const { token } = res.data;
                    service.setToken(token);

                    //重置失败请求的配置
                    config.headers['Authorization'] = 'Bearer ' + token;

                    //已经刷新了token，将所有队列中的请求进行重试
                    requests.forEach(cb => cb(token));
                    // 重试完了别忘了清空这个队列
                    requests = [];

                    return service(config)
                } else {
                    console.log('refresh' + res);
                }

            }).catch(res => {
                //重新请求token失败，跳转到登录页
                router.push({
                    path: '/login'
                });
            }).finally(() => {
                //完成之后在关闭状态
                isRefreshing = false
            })
        } else {
            // 正在刷新token，返回一个未执行resolve的promise
            return new Promise((resolve) => {
                // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                requests.push((token) => {
                    config.headers['Authorization'] = 'Bearer ' + token;
                    resolve(service(config))
                })
            })
        }
    }
    return response
}, error => {
    console.log(error);
    return Promise.reject(error)
});

//页面token变化时设置最新token
export function setToken(token) {
    return service.setToken(token)
}
// 封装axios的post请求
export function fetch_post(url, params) {
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: url,
            data: params
        })
            .then(response => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

// 封装axios的post请求---2
export function fetch_post2(url, data) {
    let formdata = new FormData();
    for (let i in data) {
        formdata.append(i, data[i])
    }
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: url,
            data: formdata,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
// 封装post请求---action
export function fetch_post_action (url, data) {
    return new Promise((resolve, reject) => {
        service.post(url, qs.stringify(data),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
// 封装axios的get请求
export function fetch_get(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params
        }).then(response => {
            resolve(response.data)
        })
            .catch((error) => {
                reject(error)
            })
    })
}
//封装axios的put请求
export function fetch_put(url, params) {
    return new Promise((resolve, reject) => {
        service.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
//封装axios的delete请求
export function fetch_deletefn(url, params) {
    return new Promise((resolve, reject) => {
        service({
            method: 'delete',
            url: url,
            data: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            })
    })
}

//暴露
export default {
    Post_data(url, params) {
        return fetch_post(url, params)
    },
    Post_data2(url, params) {
        return fetch_post2(url, params)
    },
    Post_data_action (url, params) {
        return fetch_post_action(url, params)
    },
    Get_data(url, params) {
        return fetch_get(url, params)
    },
    Put_data(url, params) {
        return fetch_put(url, params)
    },
    Delete_data(url, params) {
        return fetch_deletefn(url, params)
    },
    setToken(token) {
        return setToken(token)
    }
}

