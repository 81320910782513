const OSS = require('ali-oss')

const OSSConfig = {
  uploadHost: 'http://axaction.oss-cn-beijing.aliyuncs.com', //OSS上传地址
  ossParams: {
    region: 'oss-cn-beijing',
    success_action_status: '200', // 默认200
    // accessKeyId: 'LTAI5tGcqBbkv93YXUbNP5DW',
    // accessKeySecret: 'Yzaahe0OxXSZaNFLPQ8lbghD4VJrdV',
    accessKeyId: 'LTAI5t8TJ1S8a6h4xfYX3u9C',
    accessKeySecret: 'cWLrtw1UkSXstQc4e0k8QUcWPbU49V',
    bucket: 'axaction',
  },
}



function random_string(len) {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function uploadOSS(file, callback) {
  return new Promise(async (resolve, reject) => {
    const fileName = `${random_string(6)}_${file.name}`
    let client = new OSS({
      region: OSSConfig.ossParams.region,
      accessKeyId: OSSConfig.ossParams.accessKeyId,
      accessKeySecret: OSSConfig.ossParams.accessKeySecret,
      bucket: OSSConfig.ossParams.bucket,
    })
    const res = await client.multipartUpload(fileName, file, {
      progress: async function (p) {
        // console.log(Number((p * 100).toFixed(0)));
        if (callback) {
          const progress = Number((p * 100).toFixed(0));
          callback(progress);
        }
      }
    })
    console.log(res)
    // resolve(res)
    // 或者返回如下：
    resolve({
      fileUrl: `${OSSConfig.uploadHost}/${fileName}`,
      fileName: file.name,
      relativeUrl: `${fileName}`, //相对路径
      thumbnailsMontageUrl: '?x-oss-process=image/format,webp',//压缩-拼接
    })
  })
}

export default uploadOSS;

